import * as React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"



//On importe les composants
import Header from '../components/header'
import Adresse from '../components/adresse'
import Footer from '../components/footer'
import BandeauContact from '../components/bandeau-contact'

//On importe les images
import Favicon from '../images/favicon.jpg'

//On importe le CSS
import'../css/main.scss'
import '../css/footer.scss'
import '../css/adresse.scss'
import '../css/article.scss'
import '../css/bandeau-accueil.scss'
import '../css/bandeau-contact.scss'
import '../css/bandeau-page.scss'
import '../css/domaines.scss'
import '../css/formulaire.scss'
import '../css/header.scss'
import '../css/presentation.scss'
import '../css/resultats.scss'
import '../css/tableau.scss'


export default function AlcoolauVolant() {


    const Sommaire = [
        { titre: "Conduire sous l'emprise de l'alcool", ancre: "conduire", lien: "#conduire" },
        { titre: "Que va t'il se passer suite à l'interpellation ?", ancre: "interpellation", lien: "#interpellation" },
        { titre: "Quelles sont les sanctions encourues ?", ancre: "sanction", lien: "#sanction" },
        { titre: "Jeune permis, vais-je le perdre ?", ancre: "perdre", lien: "#perdre" },
        { titre: "Contester un taux d'alcoolémie contraventionnel ?", ancre: "contravention", lien: "#contravention" },
        { titre: "En cas de récidive, vais-perdre mon permis ?", ancre: "recidivie", lien: "#recidive" },
        { titre: "La comparution sur reconnaisance préalable de culpabilité (CRPC), un piège ?", ancre: "piege", lien: "#piege" }
    ]


    return (
        <HelmetProvider>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Alcool au Volant : Comment contester l'infraction ?</title>
                <meta name="description" content="Conduire un véhicule après avoir consommé de l’alcool est une infraction pénale dès lors le taux d’alcool par litre d’air expiré est supérieur à 0,25 mg/l (0,10 mg/l pour les permis probatoires)." />
                <html lang="fr" />
                <link rel="icon" href={Favicon} />


            </Helmet>

            <Header />

            <div className="BackGrey">
                <div className="BandeauMain">

                    <div className="BandeauPageContainer">

                        <h1>Alcool au Volant</h1>
                        <h2 align="center">Comment faire pour contester ?</h2>

                    </div>

                </div>

            </div>


            <div className="AccrochePageMain">

                <div className="AccrochePageContainer">

                    <div className="AccrochePageItem">

                        <StaticImage src="../images/portrait-debrabant.jpg" alt="thierry debrabant avocat" placeholder="blurred" layout="fixed" width={200} height={200} />

                    </div>

                    <div className="AccrochePageItem">

                        <h4 align="center">Maître Thierry DEBRABANT, Avocat</h4>

                        <p align="justify">
                            “J'obtiens régulièrement des dispenses de peine pour mes clients, évitant ainsi la perte des 6 points.”
                        </p>

                        <div className="AccrochePageItemLink">
                            <Link to="#contact">Contacter</Link>
                        </div>

                    </div>


                </div>
            </div>


            <div className="SommairePage">
                <h2>SOMMAIRE</h2>
                <ul>
                    {
                        Sommaire.map((item, i) => {
                            return (
                                <li key={i}><a href={item.lien}>{item.titre}</a></li>
                            )
                        })
                    }

                </ul>
            </div>

            <div className="TableauMain">

                <h3 align="center" id={Sommaire[0].ancre}>{Sommaire[0].titre}</h3>
                <div className="Contenu">
                    <p align="justify">Conduire un véhicule après avoir consommé de l’alcool est une <b>infraction pénale</b> dès lors le taux d’alcool par litre d’air expiré est <b>supérieur à 0,25 mg/l</b> (0,10 mg/l pour les permis probatoires).</p>

                    <p align="justify"><b>Entre 0,25 et 0,39mg/l d’air expiré c’est une contravention</b> (c’est à dire une infraction qui n’est pas punissable par une peine d’emprisonnement).</p>

                    <p align="justify"><b>Au delà de 0,40 mg/l d’air expiré, c’est un délit</b> prévu et réprimé par les articles L 234-1 et suivants du Code de la Route.</p>

                </div>

                <h3 align="center" id={Sommaire[1].ancre}>{Sommaire[1].titre}</h3>
                <div className="Contenu">
                    <p align="justify">Le permis de conduire fait généralement l’objet d’une <b>rétention de 72 heures immédiate</b>.</p>

                    <p align="justify">Cette rétention est dans la plupart des cas <b>suivie d’une décision de suspension administrative</b> prise par le Préfet ou le Sous-Préfet.</p>
                    <p align="justify">Cette décision de suspension est notifiée par lettre recommandée avec accusé de réception adressée au domicile ou remise en main propre par les forces ultérieurement.</p>
                    <p align="justify">Le Préfet peut suspendre le permis de conduire <b>pour une durée maximale de 12 mois</b>.</p>
                    <p align="justify">Le délai de suspension est principalement fixé <b>en fonction du taux d’alcoolémie</b>, un barème est établi pour chaque département.</p>
                    <p align="justify">Quelques semaines plus tard, le Procureur décide selon quelle procédure vous serez poursuivi judiciairement.</p>

                    <br />
                    <p align="justify"><u>Il existe en effet <b>5 procédures</b> pour juger les infractions au code de la route :</u></p>
                    <ul>
                        <li>La procédure de composition pénale,</li>
                        <li>La procédure de l’ordonnance pénale délictuelle,</li>
                        <li>La procédure de comparution sur reconnaissance préalable de culpabilité (CRPC),</li>
                        <li>La convocation devant le Tribunal Correctionnel (COPJ),</li>
                        <li>L’ouverture d’une information judiciaire pour les faits complexes et graves comme les homicides involontaires.</li>
                    </ul>
                    <br />


                    <p align="justify">Généralement, des poursuites pour avoir conduit un véhicule terrestre à moteur implique qu’un taux d’alcool mesuré par un appareil éthylomètre ou par prise de sang puisse être reproché au conducteur.</p>
                    <p align="justify">Mais attention, <b>même si le conducteur n’est pas en état de souffler dans l’éthylomètre</b>, mais que l’état d’ivresse se manifeste par le comportement de manière évidente et objective, alors il est <b>possible pour le Procureur de poursuivre</b> pour conduite en état d’ivresse manifeste, les sanctions des deux infractions sont identiques.</p>
                    <p align="justify">On peut lire sur certains sites ou forum internet qu’il peut être avantageux de refuser de souffler dans l’éthylotest.</p>
                    <p align="justify"><b>C’est en réalité une très mauvaise idée</b> car ceux qui prodiguent ce genre de conseil semblent ignorer qu’il existe en plus de la conduite en état alcoolique et en état d’ivresse manifeste le délit de refus de se soumettre aux épreuves de dépistage qui est puni des mêmes peines.</p>

                    <h3 align="center" id={Sommaire[2].ancre}>{Sommaire[2].titre}</h3>
                    <div className="Contenu">
                        <p align="justify">Pour un taux d’alcool supérieur à 0,40 mg/l d’air expiré <u>les peines principalement encourues sont :</u></p>

                        <ul>
                            <li>2 ans d’emprisonnement maximum,</li>
                            <li>4500 € d’amende délictuelle,</li>
                            <li>la suspension du permis de conduire pour une durée de 36 mois maximum,</li>
                            <li>l’annulation du permis de conduire avec interdiction de le repasser pour une durée de 3 ans maximum.</li>
                        </ul>
                        <br />

                        <p align="justify">Attention, <b>la perte des 6 points n’est pas une peine décidée par le Tribunal</b>, mais la conséquence administrative automatique.</p>

                        <p align="justify">De nombreuses personnes pensent que si la perte de point n’est pas mentionnée sur le jugement ou sur le procès verbal de composition pénale, elles ne perdront pas les points !</p>

                        <p align="justify"><b>C’est malheureusement une information totalement erronée</b>, il est tout à fait normal et logique que les jugements ne mentionnent pas la perte de point, car ce n’est pas une sanction qui est laissé à l’appréciation du Juge, mais une <b>conséquence administrative automatique</b>.</p>

                        <p align="justify" id="important">En fait, il existe bien <b>un moyen pour ne pas perdre les points</b> même en cas de condamnation, il faut pour cela solliciter une dispense de peine qui est particulièrement efficace mais difficile à obtenir en matière d’infractions au code de la route.</p>

                        <p align="justify">Le cabinet de <b>Maître Thierry DEBRABANT</b> obtient néanmoins régulièrement la dispense de peine pour ses clients, les décisions sont consultables sur ce site dans la partie <a href="/">nos résultats</a>.</p>

                        <p align="justify">Vous trouverez aussi dans cette partie « nos résultats » des décisions de relaxe, ou des décisions qui ne suspendent pas le permis de conduire, ou pour un délai très court (1 jour) notamment pour des faits de conduite sous l’empire d’un état alcoolique.</p>
                    </div>


                    <h3 align="center" id={Sommaire[3].ancre}>{Sommaire[3].titre}</h3>
                    <div className="Contenu">
                        <p align="justify">Pour les permis en période probatoire, <u>la situation est rendue plus délicate</u> par la conjonction de deux facteurs :</p>

                        <ul>
                            <li>D’abord par l’abaissement du taux légal d’alcoolémie à 0,10 mg/l d’air expiré,</li>
                            <li>Ensuite par le <b>nombre de points sur le permis de conduire</b> limité à 6 la première année, et qui restera bloqué à 6 maximum en cas de commission de la moindre infraction cette même première année.</li>
                        </ul>
                        <br />


                        <p align="justify">En fait, <b>contrairement aux apparences</b>, la situation est loin d’être désespérée et le permis peut être sauvé dans <u>99 % des cas</u>.</p>

                        <p align="justify">De nombreuses stratégies existent et peuvent être mise en œuvre efficacement pour protéger le permis de conduire.</p>

                        <BandeauContact />
                    </div>


                    <h3 align="center" id={Sommaire[4].ancre}>{Sommaire[4].titre}</h3>
                    <div className="Contenu">

                        <p align="justify">La conduite sous l’empire d’un état alcoolique devient une <b>« simple » contravention</b> si le taux d’alcoolémie supérieur à 0,25mg/l d’air expiré et inférieur à 0,40mg/l d’air expiré.</p>

                        <p align="justify">La différence entre une contravention (en dessous de 0,40 mg/l) et un délit (à partir de 0,40 mg/l) est conséquente puisque la principale différence est que <b>la contravention n’est pas punissable par une peine d’emprisonnement</b>.</p>

                        <br />
                        <p align="justify"><u>Les peines sont les suivantes :</u></p>
                        <ul>
                            <li>750 € d’amende contraventionnelle,</li>
                            <li>3 ans de suspension (au maximum) du permis de conduire.</li>
                        </ul>
                        <br />



                        <p align="justify">Attention, que la conduite en état alcoolique soit contraventionnelle (en dessous de 0,40 mg/l) ou délictuelle (à partir de 0,40 mg/l), <b>le nombre de points qui risquent d’être retranché du permis de conduire est le même</b>, c’est toujours 6 points.</p>

                        <p align="justify">Contrairement aux idées reçues, une contravention pour conduite d’un véhicule avec un taux d’alcoolémie contraventionnel est tout à fait <b>contestable tant que vous n’avez pas payé l’amende</b>, peu importe que vous ayez signé sur le boitier électronique de l’agent.</p>
                        <p align="justify">Avoir reconnu le taux d’alcoolémie retenu lors de votre interpellation n’empêche absolument pas de contester la contravention à postériori.</p>
                        <p align="justify">La contestation est <b>possible pendant un délai de 45 jours</b> après la réception de l’avis de contravention.</p>
                        <p align="justify">Après l’expiration de ce délai, un second avis de contravention, celui de l’amende forfaitaire majorée est adressé au contrevenant, <b>ce qui ouvre un nouveau délai de 3 mois pour contester l’infraction</b>.</p>
                        <p align="justify"><b>Dans le doute sur votre solde de points</b>, <u>il vaut mieux ne rien faire plutôt que de régler l’amende</u>, car ensuite il ne sera plus possible de contester ni sur le plan pénal, ni le retrait de points.</p>
                        <p align="justify"><b>Je peux intervenir à vos côtés</b>, vous assister et vous remplacer dans ces démarches.</p>
                        <p align="justify">Je me chargerai de l’étude des pièces de votre dossier pour si prioritairement présenter à l’audience des conclusions dites « in limine litis » afin de <b>faire annuler la procédure</b>.</p>

                        <br />
                        <p align="justify">A défaut d’irrégularités procédurales, <u>je ferai tout mon possible pour que</u> :</p>
                        <ul>
                            <li>Vous obteniez une décision adaptée à vos attentes,</li>
                            <li>Le retrait de point intervienne le plus tard possible de façon à ce que votre permis de conduire conserve sa validité.</li>
                        </ul>
                        <br />

                        <p align="justify">Je vous rappelle que vous pouvez consulter sur ce site une large sélection de décisions obtenues par mes soins dans la rubrique <a href="/">nos résultats</a>.</p>

                        <p align="justify">En la matière, mieux vaut la preuve des bons résultats par les avis d’autres clients ou par la consultation directe des résultats obtenus par le cabinet : <b>des résultats, pas de blabla !</b></p>

                        <p align="justify">Il n’y a aucune fatalité, le code de la route est une matière où les erreurs sont extrêmement fréquentes, <b>l’éthylomètre est loin d’être une machine infaillible</b> et il arrive régulièrement que les taux relevés ne soient pas les bons !</p>

                    </div>


                    <h3 align="center" id={Sommaire[5].ancre}>{Sommaire[5].titre}</h3>
                    <div className="Contenu">
                        <p align="justify">Il est vrai que la loi prévoit qu’en cas de récidive de condamnation pour des frais de conduite en état alcoolique, <b>le permis de conduire est automatiquement annulé</b>.</p>

                        <p align="justify">C’est la loi qui en dispose ainsi, c’est à dire qu’en cette hypothèse <b>le Tribunal n’a théoriquement aucun pouvoir d’appréciation</b>, on dit qu’il constate l’annulation du permis de conduire.</p>

                        <p align="justify">En réalité, de <u>nombreuses possibilités et stratégies</u> permettent d’éviter l’annulation du permis de conduire.</p>

                        <p align="justify">Les avocats qui vous indiquent qu’en cas de récidive, l’annulation du permis de conduire est inévitable et automatique ne maitrisent tout simplement pas la matière du droit pénal routier.</p>

                        <br />
                        <p align="justify">En synthèse et de manière non exhaustive, <u>il existe au moins 3 méthodes pour éviter l’annulation du permis de conduire </u>:</p>
                        <ul>
                            <li>obtenir la relaxe sur le fond,</li>
                            <li>obtenir l’annulation de la procédure, ce qui abouti également à une relaxe,</li>
                            <li>faire disparaître la circonstance de la récidive légale.</li>
                        </ul>
                        <br />
                        <p align="justify">Une fois de plus, <b>il n’y a aucune fatalité</b>, il y a toujours possibilité d’obtenir un bon résultat en prenant attache avec un avocat spécialisé en matière de code de la route et de droit pénal.</p>
                    </div>

                    <h3 align="center" id={Sommaire[6].ancre}>{Sommaire[6].titre}</h3>
                    <div className="Contenu">
                        <p align="justify"><b>Vous êtes soupçonné d’avoir commis un délit routier</b> (alcool ou stupéfiants au volant), et l’Officier vous remet une convocation pour être jugé selon la procédure de la comparution sur reconnaissance préalable de culpabilité (CRPC) en vous expliquant que le Procureur vous fait une faveur, que votre sanction sera fortement diminuée avec cette procédure, <b>vrai ou faux ?</b></p>
                        <p align="justify"><u>En réalité, cette affirmation est en grande partie fausse</u>.</p>
                        <br/>
                        <p align="justify">En effet, en acceptant cette procédure, <b>vous ne pouvez plus contester les faits</b>, ce qui aurait pu vous permettre d’échapper à toute sanction pénale.</p>
                        <p align="justify">Il est donc inexact de soutenir comme le font souvent les forces de l’ordre que cette procédure constitue une faveur, car en réalité en choisissant ce biais procédural, <b>le Procureur se garantie une condamnation quasi certaine</b>.</p>
                        <p align="justify">Certains confrères (et amis) spécialisés en droit pénal routier <b>conseillent d’ailleurs de refuser systématiquement la procédure de CRPC</b> afin de contester les faits devant le Tribunal Correctionnel.</p>
                        <p align="justify"><b>Cette approche systématique me paraît critiquable</b>.</p>
                        <p align="justify">En effet, j’ai a déjà obtenu de nombreux <u>classements sans suite</u> à l’occasion de la procédure de CRPC.</p>

                        <br />
                        <p align="justify">La procédure de CRPC peut effectivement <b>apparaître comme piégeuse</b>, un avocat expérimenté pourra en réalité en exploiter favorablement <u>les nombreux avantages :</u></p>
                        <ul>
                            <li>En premier lieu, il est toujours possible de <b>refuser la proposition de peine</b> qui est formulée, la procédure de CRPC n’est donc pas un choix irréversible, on peut tout à fait accepter de comparaitre, puis en fonction de ce qui est proposé, refuser la proposition de peine,</li>
                            <li>En second lieu, la procédure de CRPC présente l’immense avantage de <b>permettre une discussion en dehors du cadre de l’audience correctionnelle</b> classique, directement avec le Procureur de la République, ce qui permet de solliciter le classement sans suite et d’éviter ainsi tout procès,</li>
                            <li>En troisième lieu, il est enfin possible de s’assurer le <b>bénéfice d’une absence de mention</b> sur le bulletin n°2 du casier judiciaire.</li>
                        </ul>
                        <br />

                        <p align="justify">Une bonne stratégie de défense est <b>nécessairement personnalisée</b>, et ne peut donc consister en une approche systématique dont le but est souvent de facturer plus cher le client. </p>

                        <BandeauContact />
                    </div>


                </div>



            </div>

            <Adresse />

            <Footer />


        </HelmetProvider >
    )
}
